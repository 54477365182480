.filters-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 30px 15px 30px;
}

@media only screen and (max-width: 900px) {
  .filters-container {
    justify-content: center;
  }
}

.filter-container {
  padding: 10px;
}

@media only screen and (max-width: 900px) {
  .filter-container {
    padding: 4px;
  }
}


.filter-results-container {
  display: flex;
}

.filter-results-buttons-container {
  display: flex;
}

.filter-button {
  display: flex;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
}

.show-games-table-wrapper {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.text-faction-show-all {
  padding-left: 50px;
  padding-top: 5px;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .text-faction-show-all {
    padding-left: 40px;
    text-decoration: underline;
    cursor: pointer;
  }
}

.trends-title-up {
  font-size: 28px;
  padding: 10px 0px;
  color: #679552;
}

.trends-title-down {
  font-size: 28px;
  padding: 10px 0px;
  color: #de7b6c;
}

.trends-container {
  padding-left: 40px;
}

@media only screen and (max-width: 900px) {
  .trends-container {
    padding-left: 0px;
  }
}

.trends-title-patches {
  padding-top: 0px;
}

@media only screen and (max-width: 900px) {
  .trends-title-patches {
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
}

.filters-result-text {
  color: white;
  font-family: 'CustomFont';
  font-size: 17px;
}

@media only screen and (max-width: 900px) {
  .filters-result-text {
    color: white;
    font-family: 'CustomFont';
    font-size: 15px;
  }
}