* {
    box-sizing: border-box;
}

html {
    background-color: #181818;
}

.filter-results-text {
    color: white;
    font-family: "CustomFont";
}

body {
    background-color: #181818;
    min-height: 100%;
}

h1 {
    text-align: center;
    font-size: 48px;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #222;
}

.app-bg {
    background-color: #181818;
}

.custom-font {
    font-family: "CustomFont";
}

.content-wrapper {
    padding: 10px 100px 20px 100px;
}

@media only screen and (max-width: 900px) {
    .content-wrapper {
        padding: 0 10px 25px 10px;
    }
  }

.spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 240px;
}

.lds-dual-ring {
    color: white;
}

.lds-dual-ring,
.lds-dual-ring:after {
    box-sizing: border-box;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6.4px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.flex-row {
    display: flex;
    flex-direction: row;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    height: 56px;
    width: 100%;
    background-color: #2c3e50;
}

@media only screen and (max-width: 900px) {
    .nav-container{
        justify-content: flex-start;
    }
}

.logos-wrapper {
    display: flex;
    direction: row;
}

.app-logo {
    color: white;
    font-size: 19px;
    cursor: pointer;
    margin: 8px 20px 0px 45px;
    padding: 0px 10px;
    background-color: #e74c3c;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-weight: 600;
}

@media (any-pointer: coarse) {
    .app-logo {
        display: none;
    }
}

.hd-logo {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.hd-logo img {
    width: 50% !important;
    max-width: 500px;
}

@media (any-pointer: fine) {
    .hd-logo img {
        min-width: 380px;
    }
}

@media (any-pointer: coarse) {
    .hd-logo img {
        width: 90% !important;
    }
}

.menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    background-color: #2c3e50;
    padding-right: 50px;
}

.menu a {
    display: flex;
    width: 100%;
    width: 220px;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    position: relative;
    font-size: 20px;
    z-index: 9;
}

.menu a:hover {
    background-color: #49adc9;
}

@media (any-pointer: fine) {
    .menu {
        margin-right: 150px;
    }
}

.menu-active {
    background-color: #e74c3c;
}

.dropdown-button .dropdown-toggle {
    background-color: #2c3e50 !important;
    padding: 10px 30px 10px 30px;
    font-family: "CustomFont";
    font-size: 17px;
    border: none !important;
    flex-basis: 80% !important;
}

@media only screen and (max-width: 900px) {
    .dropdown-button .dropdown-toggle {
        margin-top: 4px;
        padding: 8px 15px 8px 15px;
        font-size: 15px;
    }
}

.dropdown-toggle::after {
    display: none !important;
}

.dropdown-toggle:hover {
    background-color: #56799c !important;
}

.dropdown-menu button {
    padding: 10px 70px 10px 30px;
}

.dropdown-menu {
    background-color: #2c3e50 !important;
    font-family: "CustomFont";
}

.dropdown-menu .dropdown-item {
    background-color: #2c3e50 !important;
    color: white;
}

.dropdown-menu .dropdown-item:hover {
    background-color: #56799c !important;
    color: white;
}

.text-small {
    color: white;
    font-family: 'CustomFont';
    font-size: 16px;
}

.text-medium {
    color: white;
    font-family: 'CustomFont';
    font-size: 19px;
}


.table-loadout-wrapper {
    display: flex;
    direction: row;
    gap: 12px;
}

.table-loadout-row-wrapper {
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding: 10px 20px;
}

@media only screen and (max-width: 900px) {
    .table-loadout-row-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 10px;
    }
}

.stratagem-rankings-item {
    color: white;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    font-size: 30px;
    padding-left: 15px;
    padding-top: 10px;
}

.stratagem-rankings-number {
    font-family: "CustomFont";
    font-size: 66px;
}

.stratagem-rankings-number-small {
    font-family: "CustomFont";
    font-size: 30px;
}

.stratagem-rankings-text-wrapper {
    padding-top: 18px;
    padding-left: 3px;
}

.stratagem-rankings-text-small {
    font-family: "CustomFont", sans-serif;
    font-size: 19px;
    padding-left: 10px;
    padding-bottom: 0px;
}

@media only screen and (max-width: 900px) {

    .stratagem-rankings-item{
        height: 70px;
        justify-content: center;
        display: flex;
    }
    .stratagem-rankings-number {
        font-size: 50px;
    }
    .stratagem-rankings-number-small {
        font-family: "CustomFont";
        font-size: 25px;
    }
    .stratagem-rankings-text-small{
        font-size: 18px;
    }
    .stratagem-rankings-text-wrapper {
        padding-top: 5px;
    }
}

.stratagem-rankings-text {
    font-family: "CustomFont", sans-serif;
    letter-spacing: 2px;
    display: inline-block;
    font-size: 32px;
    transform-origin: 0 0;
    margin-bottom: -50%;
    font-size: 26px;
    padding-left: 10px;
    padding-bottom: 2px;
}

.item-img-wrapper {
    height: 50px;
    width: 50px;
    padding: 5px;
    border-radius: 10px;
    border: 2px solid white;
}

.about-wrapper {
    min-height: 100vh;
    height: 100%;
}

.about-text-title {
    font-size: 30px;
    font-family: "CustomFont";
    color: white;
    padding-top: 30px;
}

.about-text {
    font-family: "CustomFont";
    color: white;
    font-size: 22px;
    padding: 12px 20px;
}

.about-text-title-highlight {
    color: #e74c3c;
}

.about-text-padded {
    padding-left: 20px;
    padding-top: 0;
}

.about-text-large {
    font-size: 26px;
    font-family: "CustomFont";
    color: white;
    padding: 20px 0px 10px 0px;
}

.about-text-contact {
    padding-left: 20px;
    padding-top: 10px;
}

.bar-chart-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.chart-empty-text {
    color: white;
    font-size: 22px;
    font-family: "CustomFont";
    text-align: center;
    padding-top: 110px;
}

.screenshot-toggle-text {
    text-decoration: underline;
    cursor: pointer;
    font-size: 18px;
    padding-left: 15px;
    padding-bottom: 5px;
}

.pagination {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    padding: 0px 12px 12px 12px;
}

@media only screen and (max-width: 900px) {
    .pagination {
        display: flex;
        justify-content: flex-start;
        gap: 0.5rem;
        padding: 0px 12px 12px 12px;
    }
    
}

.pagination button {
    padding: 0.5rem 1rem;
    background-color: #373b3e;
    color: white;
    border: 1px solid #444;
    cursor: pointer;
    border-radius: 10px;
}

.pagination button.active {
    background-color: #555;
    font-weight: bold;
}

.pagination button:disabled {
    background-color: #222;
    cursor: not-allowed;
}

.react-tabs__tab {
    color: white;
    font-size: 16px;
    font-family: "CustomFont";
    border-radius: 8px !important;
    margin: 0px 10px;
    border: 1px solid white !important;
}

.react-tabs__tab:focus:after {
    display: none !important;
}

.tabs-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.custom-tab-list{
    margin-bottom: 0px !important;
}

@media only screen and (max-width: 900px) {
    .custom-tab-list{
        margin-bottom: 0px !important;
        padding-left: 0px !important;
    }
}

.end-element {
    margin-left: auto;
    padding-right: 20px;
}

@media only screen and (max-width: 900px) {
    .end-element{
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
    }
}

.react-tabs__tab-panel{
    padding: 15px 0px 15px 0px !important;
}

@media only screen and (max-width: 900px) {
    .react-tabs__tab-panel{
        padding: 10px !important;
    }
}

