body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@font-face {
    font-family: "CustomFont";
    src: local("SinclairMedium"),
        url("../fonts/SinclairMedium.otf") format("opentype");
}
