.groups-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
}

.groups-title-wrapper {
    display: flex;
    flex-direction: column;
}

.groups-title-text-wrapper {
    color: white;
    margin-left: 20px;
    font-size: 21px;
    padding: 20px 0px 0px 10px;
    width: 30%;
    border-bottom: 1px solid white;
    font-family: 'CustomFont';
}

.group-container {
    display: flex;
    direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 20px;
    gap: 6px;
}

.armory-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 140px;
}

@media only screen and (max-width: 900px) {
    .armory-item-wrapper{
        width: 100px;
    }
}

.armory-item-name {
    color: white;
    text-align: center;
    width: 130px;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 10px;
    font-family: 'CustomFont';
}

@media only screen and (max-width: 900px) {
    .armory-item-name {
        width: 90px;
        font-size: 14px;
    }
}