.strategem-title-wrapper {
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    padding-bottom: 5px;
    width: 100%;
}

.stratagem-title {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 15px;
}

@media only screen and (max-width: 900px) {
    .stratagem-title {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
    }
}


.stratagem-title-img {
    width: 100px;
    height: 100px;
}

.stratagem-title-text {
    color: white;
    font-size: 32px;
    font-family: "CustomFont";
    display: flex;
    align-items: flex-end;
    padding: 0px 0px 5px 20px;
}
@media only screen and (max-width: 900px) {
    .stratagem-title {
        justify-content: center;
        font-size: 30px;
    }
}


.stratagem-filters-container {
    display: flex;
    flex-direction: row;
    width: 50%;
}

.stratagem-filter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 15px 15px;
}

@media only screen and (max-width: 900px) {
    .stratagem-filter-container {
        justify-content: center;
        align-items: flex-start;
        margin-top: 5px;
        padding: 0px 0px 15px 15px;
    }
}

.strategem-divider {
    height: 5px;
    border-top: 1px solid white;
}

.stratagem-graph-wrapper-faction {
    border-radius: 15px;
    height: 220px;
    width: 100%;
    padding: 0px 20px;
    margin-top: 5px;
}

@media only screen and (max-width: 900px) {
    .stratagem-graph-wrapper-faction {
        margin-top: 20px;
        height: 180px;
    }
}

.stratagem-graph-wrapper-patch {
    height: 240px;
    width: 100%;
    margin-top: 5px;
}

@media only screen and (max-width: 900px) {
    .stratagem-graph-wrapper-patch {
        height: 200px;
        padding-right: 15px;
    }
}

.stratagem-mission-graph-wrapper {
    padding: 5px 10px !important;
    height: 100px;
    width: 100%;
}

.stratagem-graph-title {
    color: white;
    font-size: 22px;
    padding-top: 15px;
    padding-left: 20px;
    padding-bottom: 10px;
    margin-top: -50px;
    font-family: "CustomFont";
}

.stratagem-graphs-title {
    padding-top: 5px;
    padding-left: 16px;
    padding-bottom: 5px;
    color: white;
    font-size: 22px;
    font-family: "CustomFont";
}

@media only screen and (max-width: 900px) {
    .stratagem-graphs-title {
        padding-top: 20px;
    }
}

.stratagem-other-graph-wrapper {
    padding: 5px 10px !important;
    height: 160px;
    width: 100%;
}

.stratagem-loadouts-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 45%;
    padding-bottom: 5px;
    padding-left: 15px;
}

@media only screen and (max-width: 900px) {
    .stratagem-loadouts-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-left: 20px;
    }
}

.stratagem-loadouts-section-wrapper {
    padding: 15px 0px 10px 0px;
    display: flex;
    flex-direction: column;
    width: 50%;
}

.stratagem-loadouts-title {
    font-family: "CustomFont", sans-serif;
    color: white;
    padding: 5px 5px 0px 5px;
    font-size: 19px;
}

.stratagem-other-title {
    font-family: "CustomFont", sans-serif;
    color: white;
    padding: 0px 5px 0px 5px;
    font-size: 19px;
    transform: translateY(10px);
}

.companion-chart-wrapper {
    padding: 5px 10px;
}

.companion-charts-wrapper {
    margin-top: 100px;
}